export default {
    '1001':'About Us',
    '1002':'Honorary Awards',
    '1003':'Cooperative brand merchants',
    '1004':'Company Introduction',
    '1005':'Company Introduction',
    // '1005':'Corporate Profile',
    '1006':'The company was established in December 2016, based on a vast network of shared charging services, with the corporate mission of "extending for a better life", committed to providing users with fast, convenient, and easily accessible mobile device charging services. It is a leading provider of shared power bank services in China.',
    '1007':'The company has strategic financing from well-known venture capital institutions including Tencent, Ant, Suning, Jinshajiang Venture Capital, Sequoia Capital, etc. It has been selected into the list of Hangzhou Unicorn Enterprises for three consecutive years, won the title of "National High tech Enterprise", and is the director unit of China Internet Association.',
    '1008':'Xiaodian Technology arranges various forms of products, such as large cabinets, small cabinets, and mobile charging devices, in large transportation hubs such as urban shopping centers, hotels, cinemas, airports, and high-speed rail stations, chain catering brands, and entertainment and leisure consumption places, targeting different consumer scenarios. At present, Xiaodian Technology s shared power bank covers more than 2700 cities at or above the county level in China, providing on-demand shared charging services for over 450 million users, and enjoying a high-quality life with intelligence.',
    '1009':'Founder',
    // '1010':'The Founder',
    '1010':'Chairman Tang Yongbo',
    '1011':'Director of China Internet Association, member of Yuhang District CPPCC Committee of Hangzhou City.',
    '1012':'Formerly served as the head of local life transactions on Alibaba s Taobao platform, the head of Taobaos film business, the founder of Taodian, and the head of Taobao Food, Taobao Agriculture, and Special China Business.',
    '1013':'Brand investment institutions',
    '1014':'development history',
    '1015':'Chairman of Xiaodian Technology',
    '1016':'KMEC RECHARGEABLE',
    '1017':'STANDARD SERVICE FOR HIGH-FREQUENCY CONSUMPTION SCENARIOS',
    '1018':'Your address',
    '1019':'Mobile phone number',
    '1020':'Intended agency area',
    '1021':'Investment budget (over 10000 to 50000/50000 to 100000/100000)',
    '1022':'Is it a store cooperation (Yes/No)',
    '1023':'Store Name',
    '1024':'Store specific address',
    '1025':'Agency franchise',
    '1026':'Monday to Sunday',
    '1027':'Join now to obtain the project profit and loss statement',
    '1028':'*Franchising carries risks and investment needs to be cautious',
    '1029':'Choose a small battery',
    '1030':'Choose long-term co creation and win-win business partners',
    '1031':'Please enter your address',
    '1032':'Please enter your phone number',
    '1033':'Investment budget (over 10000 to 50000/50000 to 100000/100000)',
    '1034':'Apply for cooperation immediately',
    '1035':'Full scene coverage',
    '1036':'24-hour advertising dissemination self circulation',
    '1037':'Coverage of small electronic advertising resources such as entertainment, travel, commercial districts, leisure, and business,',
    '1038':'Occupy the online and offline terminal traffic entrance to meet various advertising needs',
    '1039':'Content diversification',
    '1040':'Precise targeting based on the scene and product audience',
    '1041':'450 million small electricity users nationwide, covering 2700 cities,',
    '1042':'Infiltrate the main consumer group',
    '1043':'Experience traffic empowerment',
    '1044':'Addressing the pain points of advertisers',
    '1045':'Flexible configuration of multi space combination placement',
    '1046':'Avoiding ineffective advertising and precise integration of information resources，',
    '1047':'Assist in achieving business goals',
    '1048':'Applying for cooperation',
    '1049':'Advertising with high reach',
    '1050':'Using small electricity="invisible" product planting',
    '1051':'High frequency exposure, combined with various gameplay benefits，',
    '1052':'Boost brand capability and facilitate conversion',
    '1053':'Customized advertising placement plan',
    '1054':'Efficiently facilitate various business goals',
    '1055':'Data visualization advertising effect can be accepted',
    '1056':'Driven by algorithms and technology, helping advertisers discover the most valuable audience,',
    '1057':'Maximizing the value of each launch',
    '1058':'Good news keeps circulating! Xiaodian Technology has been recognized as the first approved unicorn enterprise in Yuhang District, Hangzhou City',
    '1059':'Recently, the Economic and Information Bureau of Yuhang District, Hangzhou City, released a public notice on the list of unicorn and quasi unicorn enterprises to be recognized in Yuhang District for 2023. Hangzhou Xiaodian Technology Co., Ltd. was recognized as a quasi unicorn enterprise in Yuhang District, fully confirming the recognition of the government and industry experts for the achievements and development prospects of Xiaodian.',
    '1060':'Xiaodian Technology Subscription Account',
    '1061':'Good news! Xiaodian Technology was awarded the title of "Zhejiang Province Specialized, Refined, Special, and New Small and Medium sized Enterprise"',
    '1062':'Recently, the Department of Economy and Information Technology of Zhejiang Province announced the list of "the second batch of specialized, refined, special, and new small and medium-sized enterprises in Zhejiang Province for 2023". Hangzhou Xiaodian Technology Co., Ltd. has been recognized as a "specialized, refined, special, and new small and medium-sized enterprise in Zhejiang Province for 2023" due to its strong research and development innovation capabilities, excellent technological research and development achievements, efficient development capabilities, and practical capabilities in commercial Internet of Things. This marks a new level of high-quality development for Xiaodian.',
    '1063':'Xiaodian Technology Subscription Account',
    '1064':'Have you tried "swipe payment"? Another Black Technology Landing in Hangzhou',
    '1065':'By using "palm print+palm vein" recognition technology, the rental process can be completed with just a wave of your hand. After mobile payment, facial recognition payment has emerged, and now palm recognition payment has also been launched.',
    '1066':'Tidal news',
    '1067':'Xiaodian Technology has won the title of "2023 High Quality Growth Brand" with its strength, safeguarding a better life',
    '1068':'Recently, Inspur New Consumer announced the list of "2023 High Quality Growth Brands". As the only brand selected in the field of shared charging, Xiaodian Technology, together with industry leaders such as Miniso, NetEase Yanxuan, and Babycare, has made the list to protect consumers beautiful lives.',
    '1069':'Party building energy storage • Endurance for the future',
    '1070':'Good news keeps circulating! Xiaodian Technology has been recognized as the first approved unicorn enterprise in Yuhang District, Hangzhou City',
    '1071':'Good news! Xiaodian Technology was awarded the title of "Zhejiang Province Specialized, Refined, Special, and New Small and Medium sized Enterprise"',
    '1072':'Have you tried "swipe payment"? Another Black Technology Landing in Hangzhou',
    '1073':'Xiaodian Technology has won the title of "2023 High Quality Growth Brand" with its strength, safeguarding a better life',
    '1074':'Party building energy storage • Endurance for the future',
    '1075':'All reports',
    '1076':'Enterprise dynamics',
    '1077':'media coverage ',
    '1078':'Brand activities',
    '1079':'The the Year of the Loong started well | Spring Festival consumption was "hot", and small electricity firmly knit the electricity sharing service network',
    '1080':'Multiple key cities across the country are experiencing strong and surging vitality during the Spring Festival, with a concentrated release of demand for food, accommodation, transportation, shopping, and entertainment. Consumption is "hot and hot", and Xiaodian Technology, with its large number of points and market share, meets the electricity needs of users anytime and anywhere. During the Spring Festival, it helps tourists and citizens travel with full electricity.',
    '1081':'The the Year of the Loong started well | Spring Festival consumption was "hot", and small electricity firmly knit the electricity sharing service network',
    '1082':'Shanxi s cultural tourism is very popular during the Spring Festival holiday, and Xiaodian Technology provides power assistance',
    '1083':'As an essential "mobile charging station" for people is travel nowadays, the popularity of Shanxi is cultural tourism during the Spring Festival has also been verified through the rental data of shared power banks',
    '1084':'Shanxi Evening News',
    '1085':'Shanxi is cultural tourism is very popular during the Spring Festival holiday, and Xiaodian Technology provides power assistance',
    '1086':'Usage increased by 60% year-on-year, and during the Spring Festival, Hangzhou is consumption of tropical shared power banks increased by 60%',
    '1087':'During the Spring Festival, the consumption heat of Hangzhou was verified on the rental data of the shared power bank. According to the big data of small TVs, the use of the shared power bank in Hangzhou has risen sharply, and the use of the shared power bank in the environment of food, hotels, leisure and entertainment, shopping, etc. is higher than that in the same period last year.',
    '1088':'Usage increased by 60% year-on-year, and during the Spring Festival, Hangzhou is consumption of tropical shared power banks increased by 60%',
    '1089':'Set off at full capacity, once again strive for the peak | The 2024 Xiaodian Technology Annual Ceremony was successfully held!',
    '1090':'Dragon walking, the future is bright! On February 2nd, the 2024 Xiaodian Technology Annual Ceremony, with the theme of "Starting from Full Charge, Fighting the Peak Again", gathered Xiaodian people, partners, and special guests from all over the country to witness and review the fruitful achievements of Xiaodian, and jointly look forward to a new journey in 2024!',
    '1091':'Set off at full capacity, once again strive for the peak | The 2024 Xiaodian Technology Annual Ceremony was successfully held!',
    '1092':'Service wins the future | Xiaodian Technology won the "Best Tool Content Mini Program of the Year" award',
    '1093':'Recently, the first AIGC Network wide Mini Program Application Innovation Conference was held at the Exhibition Center and Conference Center of the Zhongguancun National Independent Innovation Demonstration Zone in Beijing. At the conference, the "Best Tool Content Mini Program of the Year" award was announced, and Xiaodian Technology, along with well-known brands such as Autohome, Baidu Netdisk, and Kingsoft Document, jointly won the award.',
    '1094':'Service wins the future | Xiaodian Technology won the "Best Tool Content Mini Program of the Year" award',
    'HeaderOneItem': 'Home Page',
    'HeaderTwoItem': 'Product Center',
    'HeaderThreeItem': 'Company News',
    'HeaderFourItem': 'Cooperation',
    'HeaderFiveItem': 'Common problem',
    'HeaderSixItem': 'Merchant cooperation',
    'HeaderSevenItem': 'Advertising cooperation',
    'HeaderEightItem': 'About Us',
    'HeaderNineItem': 'Join us',
    'HeaderTenItem': 'billing information',
    'FooterOneItem': 'Product Center',
    'FooterTwoItem': 'News Center',
    'FooterThreeItem': 'billing information',
    'FooterFourItem': 'Apply for cooperation',
    'FooterFourItemOne': 'Merchant cooperation',
    'FooterFourItemTwo': ' Agents to join',
    'FooterFourItemThree': 'ad serving',
    'FooterFiveItem': 'About KEMC',
    'FooterFiveItemOne': 'About Us',
    'FooterFiveItemTwo': 'Honorary Awards',
    'FooterFiveItemThree': 'Cooperation partners',
    'FooterFiveItemFour': 'Join us',
    'FooterInfoText1': 'Keda power bank is subject to legal regulation statement:',
    'FooterInfoText2': 'Relevant legal regulatory agencies：',
    'FooterCopyright': 'Hangzhou Youdian Technology Co., Ltd. 2016-2019 @ Copyright Zhejiang ICPB 15027995-5',
    'FooterOperateOne': 'Official email',
    'FooterOperateTwo': 'Official ws',
    'FooterOperateThree': 'Official Telegram',
    'FooterMedia1': 'MicroBlog',
    'FooterMedia2': 'WeChat official account',
    'FooterMedia3': 'Alipay life number',
    'HomeOnePcTitle': 'Xiaodian Technology, a pioneer in shared charging',
    'HomeOnePcText1': 'Business coverage in 2700 county-level and above cities across the country',
    'HomeOnePcText2': 'Accumulated registered users reached 450 million',
    'HomeOneH5Btn': 'Join now to obtain a profit list',
    'HomeTwoPcBtn': 'KMEC\'S AGENCY BUSINESS',
    'HomeTwoText': 'A variety of supportive policies, create a win-win situation together',
    'HomeThreePcBtn': 'Obtain advertising examples',
    'HomeThreeTitle': 'Diversification of content Precise delivery according to the scene and product audience',
    'HomeThreeText1': '450 million KNEC users nationwide, covering 2,700 cities, penetrating the main consumer groups.',
    'HomeThreeText': 'FULL-SCENE BUSINESS LAYOUT MULTI-DIMENSIONAL SPACE COMBINATION',
    'HomeFourPcTitle': 'KEMC Shared Mobile Power',
    'HomeFourPcText1': 'ANDROID, APPLE, TYPE-C THREE INTERFACES, ALL TO MEET THE CHARGING DEMAND',
    'HomeFivePcTitle': 'HOT MODELS',
    'HomeFivePcText1': 'MEET THE NEEDS OF DIFFERENT CONSUMER SCENARIOS, HIGH INDOOR AND OUTDOOR COMPATIBILITY',
    'HomeSixPcTitle': 'ONE KEY TO SCAN THE CODE EASY TO BORROW AND RETURN',
    'HomeSevenPcTitle': 'CO-BRANDED MERCHANTS',
    'JoinTwoTitle1': 'Join Us',
    'JoinTwoBtn': 'View all jobs',
    'JoinTwoTitle2': 'HOT JOBS',
    'JoinTwoText1': 'NO MATTER WHERE YOU COME FROM, WHAT YOUR STUDY AND WORK EXPERIENCE IS, IF YOU HAVE OUTSTANDING TALENT IN YOUR OWN FIELD, YOU ARE WELCOME TO JOIN US TO LEAD THE FUTURE OF SHARED CHARGING.',
    'JoinTwoText2': 'JOIN US IN LEADING THE FUTURE OF SHARED CHARGING.',
    'JoinThreeText1': 'Legal and Regulatory Notice:',
    'JoinThreeText2': 'Our management application is a non-open rental platform designed to provide users with the opportunity to earn income by renting shared mobile power supplies. We are committed to creating a safe and compliant environment so that partners can earn income through legal channels. In order to achieve this ambitious goal, the KEDA management terminal recruits tenants from the Americas and emphasizes that all users must strictly abide by our usage agreement and related regulations.',
    'JoinThreeText3': 'Keda Shared Power Bank Co., Ltd. has offices in the United Kingdom and Hong Kong. In accordance with Hong Kong regulations and British regulations, we are licensed to operate in Hong Kong and the United Kingdom and strictly supervise our activities. At the same time, we also have a US MSB license and strictly comply with the regulatory regulations of the US government to provide all users with a legal and compliant profit environment.',
    'JoinThreeText4': 'In order to ensure the safety of user funds, KEDA provides users with strong financial protection measures. All financial transactions conducted on our platform will be subject to strict supervision and protection. We take multiple security protection measures to ensure that user funds are fully protected in all aspects such as transfers, withdrawals, and profits.',
    'JoinThreeText5': 'We firmly believe that every user should enjoy our services in a fair and safe environment. Therefore, legal and regulatory policies are an important part of our operations.',
    'JoinThreeText6': 'We promise to be committed to providing the highest quality services, with the most professional attitude, to achieve diversified operating methods and continuous innovation. This is a new starting point for KEDA to expand the business territory of the sharing economy. We look forward to your participation and support, and work with us to create a shared low-carbon future!',
    'JoinThreeText7': 'Relevant legal and regulatory authorities',
    'JoinThreeText8': '· 《British Commercial Regulations》',
    'JoinThreeText9': '· 《British Financial Supervision Regulations》',
    'JoinThreeText10': '· 《Hong Kong Business Registration Ordinance》',
    'JoinThreeText11': '· 《Hong Kong Payment System and Deposit Business Ordinance》',
    'JoinThreeText12': '· 《US Financial Privacy Act》',
    'JoinThreeText13': '· 《American Financial Modernization Act》',
    'JoinThreeText14': '· 《Australian Securities and Investments Commission》',
    'JoinThreeText15': '· 《Canada\'s Financial Consumer Protection Framework》',
    'JoinThreeText16': 'Our company solemnly reminds all KEDA users not to log in and visit illegal websites that imitate KEDA to avoid being deceived.',
    'JoinFiveTitle': 'Leading the way to the future of shared mobile power',
    'MerchantOneTitle': 'COOPERATION SERVICES',
    'MerchantTwoTitle': 'MERCHANTS BENEFIT',
    'MerchantTwoTitle1': 'ENHANCED SERVICES',
    'MerchantTwoText1': 'CHARGING BY SCANNING THE CODE.',
    'MerchantTwoText2': 'HIGH-QUALITY SOLUTION TO CONSUMERS\' CHARGING NEEDS.',
    'MerchantTwoText3': 'ENHANCE THE SERVICE EXPERIENCE AND QUALITY OF MERCHANTS AND INCREASE THEIR COMPETITIVENESS.',
    'MerchantTwoTitle2': 'COST SAVING',
    'MerchantTwoText4': 'SAVING THE COST OF CHARGING EQUIPMENTS PROVIDED BY MERCHANTS.',
    'MerchantTwoText5': 'SELF-SERVICE, NO NEED TO BE SUPERVISED BY STORE STAFF.',
    'MerchantTwoText6': 'IMPROVE SERVICE EFFICIENCY AND REDUCE LABOR COSTS',
    'MerchantTwoTitle3': 'ADVERTISING VALUE',
    'MerchantTwoText7': 'FB, YOUTUBE, MULTIPLE WAYS TO ATTRACT TRAFFIC.',
    'MerchantTwoText8': 'PUBLIC NUMBER PROVIDES MERCHANTS WITH LOCATION, INCREASING EXPOSURE AND CUSTOMER TRAFFIC FOR FREE.',
    'MerchantTwoTitle4': 'EASY TO OPERATE',
    'MerchantTwoText10': 'THE ONLINE PROCESS GUIDES THE USE OF SIMPLE STEPS, EASY TO RETURN.',
    'MerchantTwoTitle5': 'COOPERATION GUARANTEE',
    'MerchantTwoText13': 'THOUSANDS OF CITY SERVICE TEAM.',
    'MerchantTwoText14': 'ONLINE AND OFFLINE 7*24 HOURS PERSONALIZED SERVICE, WORRY-FREE USE.',
    'MerchantTwoTitle6': 'SAFETY',
    'MerchantTwoText16': 'PRODUCTS HAVE PASSED NATIONAL CCC, CQC AND OTHER AUTHORITATIVE CERTIFICATION.',
    'MerchantTwoText17': 'All battery cells comply with GB31241 (Chinese safety regulations),',
    'MerchantTwoText18': 'All equipment is insured by People\'s Insurance of China (PICC) with a large amount of insurance',
    'MerchantThreeTitle': 'COOPERATION PROCESS',
    'MerchantThreeBtn': 'Applying for cooperation',
    'MerchantFourTitle': 'Applicable scene',
    'MerchantFiveTitle': 'Merchant cooperation',
    'MerchantFiveText': 'Win win cooperation, small battery charging, lighting up the whole country, anytime, anywhere, never power off!',
    'MerchantFiveBtn': 'Apply for cooperation immediately',
    'MerchantPanel6-1': 'Airports',
    'MerchantPanel6-2': 'Café',
    'MerchantPanel6-3': 'Bars',
    'MerchantPanel6-4': 'Convenience stores',
    'MerchantPanel6-5': 'Restaurants',
    'MerchantPanel6-6': 'Schools ',
    'MerchantPanel6-7': ' Shopping centers',
    'MerchantPanel6-8': 'Stations',
    'ProductOneTitle': 'KEMC SHARED CHARGER',
    'ProductTwoTitle': 'PRODUCT ADVANTAGES',
    'ProductThreeTitle1': 'THREE-WIRE OUTPUT',
    'ProductThreeText1': 'THREE-IN-ONE LINE OUTPUT, ANDROID, APPLE, TYPE-C THREE INTERFACES, ALL-ROUND TO MEET THE CHARGING NEEDS.',
    'ProductThreeTitle2': 'BORROW-AND-RETURN',
    'ProductThreeText2': 'PLUG IN AND RETURN',
    'ProductThreeTitle3': 'REAL-TIME VIEW',
    'ProductThreeText3': 'REAL-TIME VIEW OF USAGE TIME AND COST',
    'ProductThreeTitle4': 'SAFE AND RELIABLE',
    'ProductThreeText4': 'PASSED THE NATIONAL CCC, CQC SAFETY CERTIFICATION, AS WELL AS THE EUROPEAN UNION CE AND OTHER CERTIFICATIONS.',
    'ProductThreeTitle5': 'INTELLIGENT CURRENT',
    'ProductThreeText5': 'MCU AUTOMATICALLY RECOGNIZES THE TYPE OF CELL PHONE AND INTELLIGENTLY MATCHES THE BEST CHARGING CURRENT',
    'ProductThreeTitle6': 'RETURN TO OTHER PLACES',
    'ProductThreeText6': 'NO NEED TO WORRY ABOUT CHARGING WHEN TRAVELING.',
    'ProductFourTitle': 'FULL SCENE PRODUCT INTRODUCTION',
    'ProductFourTitle1': 'INTELLIGENT CURRENT',
    'ProductFourTitle2': 'LEAKAGE ALARM',
    'ProductFourTitle3': 'POWER MANAGEMENT',
    'ProductFourTitle4': '7-PRONGED PROTECTION',
    'ProductFourTitle5': 'BACKSTAGE MONITORING',
    'ProductFourTitle6': 'RECYCLING',
    'ProductFourTitle7': 'SMOKE ALARM',
    'ProductFiveTitle': 'Patent display',
    'StoreTitle': 'billing information',
    'StoreTableTh1': 'Store Name',
    'StoreTableTh2': 'Store address',
    'StoreTableTh3': 'Store billing',
    'AboutOneText1': 'With the advancement of global electronic technology and the worldwide popularization and application of electronic mobile devices such as tablet computers and smartphones, the need for frequent charging of electronic products has emerged. In particular, smart phones have been the essential communication tool for 95 percent of the world\'s population. While traditional power outlets are not available everywhere, the need for charging during travel or outdoor activities is a normal part of modern life, Keda Mobile Energy is a high-tech charging company that focuses on solving the need for charging during travel. ',
    'AboutOneText2': 'KEDA is a high-tech company that focuses on solving the charging needs during traveling. It is a convenient mobile power company that integrates production, leasing and operation.',
    'AboutOneText3': 'Under the sharing economy model, users can rent mobile power devices through mobile apps or other means, and pay according to the length of use or the amount of charge. This model allows more people to share resources and improve the efficiency of resource utilization, while also bringing business and profit opportunities to enterprises.',
    'AboutOneText4': 'With the popularization of mobile devices and the increased demand for convenience and sustainability, the shared mobile power industry has a broad market prospect.',
    'AboutOneText5': 'Our company\'s business model is a hosting business model, you buy a universal mobile power device in our company, that is to say, you enjoy the right to share the income of this device for two years, our company will help you manage this device and install it in some stores, bars, supermarkets, squares and all other crowded places in other countries and cities. It will automatically charge you for every hour that people use your device. This is management income. No resources, no cost, no inventory.KEDA allows millions of people to start a business together, share benefits, share resources, provide one-stop service and share the world.',
    'AgentH1Title': 'Common problems',
    'AgentCateTitle1': 'General Questions',
    'AgentCateTitle2': 'STechnical Issues',
    'AgentCateTitle3': 'Fees and Payments',
    'AgentCateTitle4': 'Cooperation questions',
    'AgentCate1QuestionTitle1': 'How does borrowing a mobile power supply work?',
    'AgentCate1QuestionText1': 'Borrowing a mobile power is very simple. Scan the QR code, enter the desired payment method and remove the mobile power. After charging, you can return the mobile power to any of our stations. To do this, you simply push the mobile power back to the station.',
    'AgentCate1QuestionTitle2': 'How do I return the mobile power?',
    'AgentCate1QuestionText2': 'You can return the mobile power to any of our stations by simply returning it to the station. You do not need to perform any other action. Your loan will be automatically terminated and you will only be charged for the period you borrowed the mobile power.',
    'AgentCate1QuestionTitle3': 'Do I need to apply to borrow a mobile power?',
    'AgentCate1QuestionText3': 'No. For speed and convenience, you can scan the QR code to get the mobile power without downloading the app.',
    'AgentCate1QuestionTitle4': 'Is the service available 24/7? ',
    'AgentCate1QuestionText4': 'Yes, mobile power devices are available 24 hours a day and can be rented at any time, 24/7, but the profit sharing for partners\' devices is from Monday to Friday, and the profit for partner stores is on Saturday and Sunday.',
    'AgentCate2QuestionTitle1': 'What devices can I charge with a mobile power supply?',
    'AgentCate2QuestionText1': 'The Mobile Power provides charging cables for all common smartphones and headphones. Available in Lightning, USB-C and Micro USB, you can easily charge any Apple and Android device.',
    'AgentCate2QuestionTitle2': 'Does the mobile power include a charging cable?',
    'AgentCate2QuestionText2': 'Yes, Powerbank provides charging cables for all common smartphones and headphones. With Lightning, USB-C and Micro USB, you can easily charge any Apple and Android device.',
    'AgentCate2QuestionTitle3': 'How long does it take to charge my smartphone?',
    'AgentCate2QuestionText3': 'Charging time depends on the respective smartphone. Typically, it can be charged to around 80% within 20 minutes.',
    'AgentCate3QuestionTitle1': 'How much does it cost to borrow a portable power supply?',
    'AgentCate3QuestionText1': 'The first 5 minutes are free! After that, charges start at $0.30-$3 for half an hour (depending on the city). The maximum usage period is 3 days. If you don\'t return your mobile power within 3 days, we will charge you $30. The mobile power is now yours.',
    'AgentCate3QuestionTitle2': 'What payment methods can I use?',
    'AgentCate3QuestionText2': 'We currently offer the following payment methods: Visa, MasterCard, American Express, PayPal, ApplePay and Google Pay.',
    'AgentCate3QuestionTitle3': 'Do I need to pay a deposit to rent a mobile power at the mall?',
    'AgentCate3QuestionText3': 'No, there is no deposit required to rent a mobile power, you only need to pay for the use of the mobile power.',
    'AgentCate4QuestionTitle1': 'How long do profits last after my purchase?',
    'AgentCate4QuestionText1': 'There is a total of 3 years effective equipment profit sharing period from the date of purchase of the equipment.',
    'AgentCate4QuestionTitle2': 'Why is there no profit on weekends?',
    'AgentCate4QuestionText2': 'After the mobile power is put on the market, you need to find places with high traffic flow for deployment. However, the rent of stores in these places is expensive, and the rent of the devices on weekends are allocated to cooperative merchants. Therefore, stores or places with mobile power devices are also one of keda\'s partners.',
    'AgentCate4QuestionTitle3': 'Why are there handling fees for withdrawals?',
    'AgentCate4QuestionText3': 'Handling fees include: labor wages, equipment maintenance, taxes on the profit of the entity\'s operations, and so on.',
    'AgentCate4QuestionTitle4': 'What should partners do when the market is mature?',
    'AgentCate4QuestionText4': 'When the market is mature, our company will open a branch office in the region, and the working mode of partners in the region will be changed from online cooperation to offline cooperation. Major agents are responsible for the partner\'s equipment on the market, partners will continue to make money through the equipment. Agents in addition to equipment to bring income, but also from the team members to get equipment dividends, so your current development has laid the foundation for your future income!',
    'AgentCate4QuestionTitle5': 'What is keda\'s purpose in recruiting partners in the Americas?',
    'AgentCate4QuestionText5': 'Based on the business philosophy of mutual benefit and common development, the company will work with its partners to maintain keda\'s leading brand awareness in the field of mobile power and stabilize market operations. The plan is to realize a 30% market share in some countries in the Americas by 2028, and to promote keda mobile power devices to every corner of the American market!'
    // 'AgentOneTitle1': 'KMEC RECHARGEABLE',
    // 'AgentOneTitle2': 'STANDARD SERVICE FOR HIGH-FREQUENCY CONSUMPTION SCENARIOS',
    // 'AgentOneText': 'Join now to obtain a profit list',
    // 'AgentFormLabel1': 'YOUR NAME',
    // 'AgentFormLabel2': 'CELL PHONE NUMBER',
    // 'AgentFormLabel3': 'INTENDED AGENT AREA',
    // 'AgentFormLabel4': 'INVESTMENT BUDGET',
    // 'AgentFormLabel5': 'WHETHER IT IS A STORE COOPERATION (YES / NO)',
    // 'AgentFormLabel6': 'STORE NAME',
    // 'AgentFormLabel7': 'STORE SPECIFIC ADDRESS',
    // 'AgentFormBtn': 'JOIN NOW GET A PROGRAM PROFIT SCHEDULE',
}
